<template>
  <div>
    <div class="d-flex justify-content-end mb-4">
      <erase-icon-button
        v-if="removeButtonVisible"
        @click="onDelete"
      />
    </div>
    <loader v-if="loading" />
    <template v-else>
      <template v-if="!isTotalCostsEmpty">
        <ib-card
          :animation="false"
          class="mb-4 pt-4 pb-3"
        >
          <distribution-of-total-costs />
        </ib-card>
        <ib-card
          :animation="false"
          class="mb-4 pt-4 pb-3"
        >
          <timeline-of-total-costs />
        </ib-card>
        <ib-card
          :animation="false"
          class="mb-4 pt-4 pb-2"
        >
          <total-costs-table />
        </ib-card>
      </template>
      <empty-placeholder v-else />
    </template>
  </div>
</template>

<script>
import DistributionOfTotalCosts from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/TotalCost/DistributionOfTotalCosts'
import EmptyPlaceholder from '@/views/Home/StoryMode/FinancialPlan/Components/EmptyPlaceholder'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import EventBus from '@/event-bus'
import Loader from '@/components/Loader'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import TimelineOfTotalCosts from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/TotalCost/TimelineOfTotalCosts'
import TotalCostsTable from '@/views/Home/StoryMode/FinancialPlan/Tables/TotalCostsTable'
import { mapState } from 'vuex'

export default {
  name: 'ExpensesTotalCosts',

  components: {
    DistributionOfTotalCosts,
    EmptyPlaceholder,
    EraseIconButton,
    Loader,
    TimelineOfTotalCosts,
    TotalCostsTable
  },

  mixins: [MixinIdeaRoles, MixinDeleteIdeaExamples, MixinApiCalculations],

  data () {
    return {
      loading: false
    }
  },

  computed: {
    ...mapState(['idea']),

    isTotalCostsEmpty () {
      return this.idea.storyMode.forecast.totalCosts.calculation.totalCosts === 0
    },

    removeButtonVisible () {
      return this.canEdit && this.totalCostHasItemsFromTemplate
    }
  },

  mounted () {
    EventBus.$on('remove-examples', () => {
      this.deleteExamples()
    })
  },

  methods: {
    onUpdate () {
      this.loading = true
      this.getAllCalculations()
        .then(() => {
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },

    deleteExamples () {
      this.deleteTotalCostExamplesMixin()
        .then(_ => {
          this.onUpdate()
        })
    }
  }
}
</script>
